<template>
  <v-row class="mx-0 mt-1">
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:item.last_hit="{ item }">
          {{ item.last_hit }}
        </template>

        <template v-slot:item.miner="{ item }">
          {{ item.miner }}
        </template>
        <template v-slot:item.firewall="{ item }">
          {{ item.firewall ? item.firewall : "-" }}
        </template>
        <template v-slot:item.list="{ item }">
          {{ item.ip_list ? item.ip_list : "-" }}
        </template>
        <template v-slot:item.allow_list="{ item }">
          {{ item.allow_list ? item.allow_list : "-" }}
        </template>
        <template v-slot:item.deny_list="{ item }">
          {{ item.deny_list ? item.deny_list : "-" }}
        </template>
        <template v-slot:item.member_timeout="{ item }">
          {{ item.member_timeout }}
        </template>
        <template v-slot:item.threats="{ item }">
          <span v-if="item.threats.length === 0">-</span>
          <div v-else>
            <v-chip
              v-for="(threat, index) in item.threats"
              :key="index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ threat }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-menu
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-on="on" v-bind="attrs">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to delete this Rule?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteRule(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:item.active="{ item }">
          <v-icon :color="activeColor(item.active)" small>
            fas fa-circle
          </v-icon>
        </template>

        <template v-slot:item.policy_call="{ item }">
          <template v-if="item.policy_call.length > 0">
            <v-chip
              v-for="(call, call_index) in item.policy_call"
              :key="call_index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ call }}
            </v-chip>
          </template>
          <template v-else> - </template>
        </template>

        <template v-slot:item.rollback_call="{ item }">
          <template v-if="item.rollback_call.length > 0">
            <v-chip
              v-for="(rollback, rollback_index) in item.rollback_call"
              :key="rollback_index"
              x-small
              color="neutral-4"
              class="mr-1 text-white"
            >
              {{ rollback }}
            </v-chip>
          </template>
          <template v-else> - </template>
        </template>

        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>

        <template v-slot:item.hitcount="{ item }">
          <span v-if="!item.resetLoading">{{
            item.hitcount ? item.hitcount : 0
          }}</span>
          <v-menu
            v-if="item.hitcount > 0 && !item.resetLoading"
            v-model="item.resetMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-bind="attrs" v-on="on">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to reset COUNTER?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        v-if="!item.resetLoading"
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelReset(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        v-if="!item.resetLoading"
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="reset(item)"
                      >
                        Reset
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-icon v-if="item.resetLoading" color="bad-2" x-small class="mr-1">
            fas fa-spinner fa-spin
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'active'"
                      width="150"
                      class="pl-3"
                    >
                      Active
                    </td>
                    <td v-if="expandItem.value === 'active'">
                      <v-icon :color="activeColor(item.active)" small>
                        fas fa-circle
                      </v-icon>
                    </td>

                    <td
                      v-if="expandItem.value === 'policy_call'"
                      width="150"
                      class="pl-3"
                    >
                      Call
                    </td>
                    <td v-if="expandItem.value === 'policy_call'">
                      <template v-if="item.policy_call.length > 0">
                        <v-chip
                          v-for="(call, call_index) in item.policy_call"
                          :key="call_index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ call }}
                        </v-chip>
                      </template>
                      <template v-else> - </template>
                    </td>

                    <td
                      v-if="expandItem.value === 'rollback_call'"
                      width="150"
                      class="pl-3"
                    >
                      Rollback
                    </td>
                    <td v-if="expandItem.value === 'rollback_call'">
                      <template v-if="item.rollback_call.length > 0">
                        <v-chip
                          v-for="(
                            rollback, rollback_index
                          ) in item.rollback_call"
                          :key="rollback_index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ rollback }}
                        </v-chip>
                      </template>
                      <template v-else> - </template>
                    </td>

                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description }}
                    </td>

                    <td
                      v-if="expandItem.value === 'delete'"
                      width="150"
                      class="pl-3"
                    >
                      Delete
                    </td>
                    <td v-if="expandItem.value === 'delete'">
                      <v-menu
                        v-model="item.deleteMenu"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            color="bad-2"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon x-small>fas fa-trash-alt</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list width="350">
                            <v-list-item>
                              <v-list-item-content class="pb-0">
                                <v-list-item-title
                                  >Are you sure you want to delete this Rule?
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-right mt-2">
                                  <v-btn
                                    rounded
                                    text
                                    color="tertiary"
                                    small
                                    class="text-capitalize"
                                    @click="cancelDelete(item)"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    rounded
                                    text
                                    color="bad-2"
                                    small
                                    class="text-capitalize"
                                    @click="deleteRule(item)"
                                  >
                                    Delete
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>

                    <td
                      v-if="expandItem.value === 'hitcount'"
                      width="150"
                      class="pl-3"
                    >
                      Hitcount
                    </td>
                    <td v-if="expandItem.value === 'hitcount'">
                      <template v-if="!item.resetLoading">
                        {{ item.hitcount ? item.hitcount : 0 }}
                        <v-menu
                          v-if="item.hitcount > 0 && !item.resetLoading"
                          v-model="item.resetMenu"
                          :close-on-content-click="false"
                          rounded
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              color="bad-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon x-small>fas fa-trash-alt</v-icon>
                            </v-btn>
                          </template>

                          <v-card>
                            <v-list width="350">
                              <v-list-item>
                                <v-list-item-content class="pb-0">
                                  <v-list-item-title
                                    >Are you sure you want to reset COUNTER?
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="text-right mt-2">
                                    <v-btn
                                      v-if="!item.resetLoading"
                                      rounded
                                      text
                                      color="tertiary"
                                      small
                                      class="text-capitalize"
                                      @click="cancelReset(item)"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      v-if="!item.resetLoading"
                                      rounded
                                      text
                                      color="bad-2"
                                      small
                                      class="text-capitalize"
                                      @click="reset(item)"
                                    >
                                      Reset
                                    </v-btn>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                        <v-icon
                          v-if="item.resetLoading"
                          color="bad-2"
                          x-small
                          class="mr-1"
                        >
                          fas fa-spinner fa-spin
                        </v-icon>
                      </template>
                    </td>

                    <td
                      v-if="expandItem.value === 'last_hit'"
                      width="150"
                      class="pl-3"
                    >
                      Last Hit
                    </td>
                    <td v-if="expandItem.value === 'last_hit'">
                      {{ item.last_hit }}
                    </td>

                    <td
                      v-if="expandItem.value === 'miner'"
                      width="150"
                      class="pl-3"
                    >
                      Miner
                    </td>
                    <td v-if="expandItem.value === 'miner'">
                      {{ item.miner }}
                    </td>

                    <td
                      v-if="expandItem.value === 'firewall'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall
                    </td>
                    <td v-if="expandItem.value === 'firewall'">
                      {{ item.firewall }}
                    </td>

                    <td
                      v-if="expandItem.value === 'mode'"
                      width="150"
                      class="pl-3"
                    >
                      Mode
                    </td>
                    <td v-if="expandItem.value === 'mode'">
                      {{ item.mode }}
                    </td>

                    <td
                      v-if="expandItem.value === 'list'"
                      width="150"
                      class="pl-3"
                    >
                      IP List
                    </td>
                    <td v-if="expandItem.value === 'list'">
                      {{ item.ip_list ? item.ip_list : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'allow_list'"
                      width="150"
                      class="pl-3"
                    >
                      Allow List
                    </td>
                    <td v-if="expandItem.value === 'allow_list'">
                      {{ item.allow_list ? item.allow_list : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'deny_list'"
                      width="150"
                      class="pl-3"
                    >
                      Deny List
                    </td>
                    <td v-if="expandItem.value === 'deny_list'">
                      {{ item.deny_list ? item.deny_list : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'member_timeout'"
                      width="150"
                      class="pl-3"
                    >
                      Member Timeout
                    </td>
                    <td v-if="expandItem.value === 'member_timeout'">
                      {{ item.member_timeout }}
                    </td>

                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      <span class="form-link" @click="open(item)">
                        {{ item.name }}
                      </span>
                    </td>

                    <td
                      v-if="expandItem.value === 'threats'"
                      width="150"
                      class="pl-3"
                    >
                      Threats
                    </td>
                    <td v-if="expandItem.value === 'threats'">
                      <span v-if="item.threats.length === 0">-</span>
                      <div v-else>
                        <v-chip
                          v-for="(threat, index) in item.threats"
                          :key="index"
                          x-small
                          color="neutral-4"
                          class="mr-1 text-white"
                        >
                          {{ threat }}
                        </v-chip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "rule-default";
    },
    activeColor(status) {
      if (status) {
        return "#87d05a";
      } else {
        return "#e53355";
      }
    },
    cancelReset(item) {
      this.$emit("cancelReset", item);
    },
    reset(item) {
      this.$emit("reset", item);
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteRule(item) {
      this.$emit("deleteRule", item);
    },
  },
};
</script>
